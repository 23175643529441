import MessageSeverity = OhhEnums.MessageSeverity;

import { Component, Input } from '@angular/core';
import { MarComManagerService } from '../../../api/marcom/mar-com-manager.service';
import { MarComRecord } from '../../../api/marcom/models/mar-com-record.model';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../../common/services/ohh-message.service';

@Component({
    selector: 'ohh-email-sign-up',
    templateUrl: './email-sign-up.component.html',
    styleUrl: './email-sign-up.component.less'
})
export class EmailSignUpComponent {

    @Input() termWidthPx = 290;
    @Input() zipWidthPx = 130;

    protected emailAddress: string;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private ohhMessageService: OhhMessageService,
                private marComManager: MarComManagerService
    ) {

    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onSubmit() {
        const marComRecord = new MarComRecord({
            email: this.emailAddress,
            isOptInNewsletter: true
        });

        this.marComManager.upsertMarComRecord(marComRecord)
            .subscribe({
                next: (record: MarComRecord) => {
                    this.ohhMessageService.showMessage('Success!', 'You have successfully signed up for our newsletter.', MessageSeverity.Success);
                },
                error: (err: Error) => {
                    this.ohhMessageService.showMessage('Error', 'We were unable to submit your request. Please try again.', MessageSeverity.Error);
                }
            })
    }
}
