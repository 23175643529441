import MessageSeverity = OhhEnums.MessageSeverity;
import PageBreak = OhhEnums.PageBreak;

import { Component, OnInit, ViewChild } from '@angular/core';
import { MegaMenuItem, MenuItem } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';
import { DeviceManagerService } from '../../common/services/device-manager.service';
import { environment } from '../../../environments/environment';
import { OhhEnums } from '../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../common/services/ohh-message.service';
import { OhhUser } from '../../api/ohh-user/models/ohh-user.model';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';
import { Router } from '@angular/router';
import { Sidebar } from 'primeng/sidebar';

@UntilDestroy()
@Component({
    selector: 'ohh-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
    @ViewChild('sidebarRef') sidebarRef!: Sidebar;

    protected menuItems: MenuItem[] = [];
    protected megaMenuItems: MegaMenuItem[] = [];
    protected showTabletMenu = false;
    protected showMobileMenu = false;
    protected isUserLoggedIn = false;
    protected loginLabel: string;
    protected suppressRegistration = false;
    protected isProvider = false;

    protected _showSidebar = false;
    set showSidebar(value: boolean) {
        if (value == null) return;

        this.broadcastService.showMobileMenu.next(value);

        setTimeout(() => {
            this._showSidebar = value;
        });
    }

    get showSidebar(): boolean {
        return this._showSidebar;
    }

    protected isMedium = false;
    protected isTablet = false;
    protected isMobile = false;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private deviceManager: DeviceManagerService,
                private ohhMessageService: OhhMessageService,
                private ohhUserManager: OhhUserManagerService,
                private router: Router,
    ) {
    }


    ngOnInit() {
        this.suppressRegistration = environment.suppressRegistration;

        this.deviceManager.windowResize
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.showTabletMenu = window.innerWidth <= PageBreak.Medium;
                this.showMobileMenu = window.innerWidth <= PageBreak.Mobile;

                this.setDeviceSize();
            });

        this.ohhUserManager.userLogInStateChanged
            .pipe(untilDestroyed(this))
            .subscribe((ohhUser: OhhUser) => {
                this.isUserLoggedIn = ohhUser != null;
                this.isProvider = this.ohhUserManager?.ohhUser?.isProvider;
                this.setLoginLabel();
            });

        this.broadcastService.hideMobileMenu
            .pipe(untilDestroyed(this))
            .subscribe(() => this.showSidebar = false);

        setTimeout(() => {
            this.isUserLoggedIn = this.ohhUserManager.isUserLoggedIn;
            this.isProvider = this.ohhUserManager?.ohhUser?.isProvider;
            this.setLoginLabel();
        }, 500);

        this.showTabletMenu = window.innerWidth <= PageBreak.Medium;
        this.showMobileMenu = window.innerWidth <= PageBreak.Mobile;

        this.setDeviceSize();
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onFindExperts() {
        // this.router.navigate(['/service-categories']);

        if (this.isMedium || this.isTablet || this.isMobile) {
            this.showSidebar = true;
        } else {
            this.broadcastService.showMegaMenu.next(true);
        }
    }

    protected onNewProvider(closeSidebar = false) {
        if (closeSidebar) { this.showSidebar = false; }

        this.router.navigate(['/early-sign-up']);
    }

    protected onLogoClick(closeSidebar = false) {
        if (closeSidebar) { this.showSidebar = false; }

        this.router.navigate(['/']);
    }

    protected toggleMobileMenu() {
        this.showSidebar = !this.showSidebar;
    }

    protected onClose(event: any) {
        this.sidebarRef.close(event);
    }

    protected onCategorySelected(categoryId: number) {
        this.showSidebar = false;
    }

    protected onNewCustomer() {
        this.router.navigate(['/register']);
        this.showSidebar = false;
    }

    protected onSignInOut() {
        if (!this.isUserLoggedIn) {
            this.router.navigate(['/log-in']);
        } else {
            this.ohhUserManager.logout();
            this.ohhMessageService.showMessage("Logged out", "You have successfully logged out.", MessageSeverity.Success);
            this.router.navigate(['/']);
        }

        this.showSidebar = false;
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setDeviceSize() {
        this.isMobile = this.deviceManager.isMobile;
        this.isTablet = this.deviceManager.isTablet;
        this.isMedium = this.deviceManager.isMedium;
    }

    private setLoginLabel() {
        this.loginLabel = this.isUserLoggedIn ? 'CASE_SENSITIVE.LOG_OUT' : 'CASE_SENSITIVE.LOG_IN'
    }
}
