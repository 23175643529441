<div id="ol-provider-signup-container" class="ol-page-container">
	<ohh-section-header [topPadding]="40" [bottomPadding]="40">
		<div class="ol-sh-heading">
			<span *ngIf="!isLoggedIn" style="text-transform: uppercase;">Create Your <strong>Account</strong></span>
			<span *ngIf="isLoggedIn" style="text-transform: uppercase;">Become a <strong>Service Provider</strong></span>
		</div>
		<div class="ol-sh-sub-heading small-margin">
<!--			<span *ngIf="!isLoggedIn">Log-In or Sign-up</span>-->
		</div>
		<div *ngIf="isLoggedIn" class="ol-sh-body small-margin">
<!--			All members have the opportunity to advertise their services and connect with clients. Continue with your Free Membership or upgrade to a-->
<!--			Preferred Membership and unlock a range of additional benefits designed to enhance your visibility and boost client trust.-->

			<em>{{ 'OHH.APP_NAME' | translate }}</em> focuses on finding qualified, skilled people to have as members on our platform.
			<br><br>
			Clicking the button below will convert your existing account into a Provider Account. You will still have the same access as before
			with the added ability to offer your services on the <em>{{ 'OHH.APP_NAME' | translate }}</em> platform.
			<br><br>
			Once your account has been transitioned into a Provider Account, you will be taken to your Account page where you can fill out the details
			of your business.
		</div>
		<div *ngIf="!isLoggedIn" class="ol-sh-body small-margin">
			To get started, log in to your existing account. Don't have one? Click the Sign Up button below.
		</div>
	</ohh-section-header>

	<ohh-full-vw-constrained-container [bottomPadding]="200">
		<div id="ol-btn-container">
			<button *ngIf="isLoggedIn" type="submit" class="ol-green-button" (click)="onBecomeAProvider()">Become a Provider. Let's Go!</button>
			<button *ngIf="!isLoggedIn" type="submit" class="ol-green-button inverted" (click)="onNavigate('/log-in')">Log In</button>
			<button *ngIf="!isLoggedIn" type="submit" class="ol-green-button inverted" (click)="onNavigate('/register')">Sign Up</button>
		</div>
	</ohh-full-vw-constrained-container>

	<ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
		<ohh-page-footer></ohh-page-footer>
	</ohh-full-vw-constrained-container>
</div>
