import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OhhProviderReview } from '../provider/models/ohh-provider-review.model';
import { ReviewApiService } from './review-api.service';

@Injectable({
    providedIn: 'root'
})
export class ReviewManagerService {

// =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private providerApiService: ReviewApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    createReview(review: OhhProviderReview): Observable<OhhProviderReview> {
        return this.providerApiService.createReview(review);
    }

    updateReview(review: OhhProviderReview): Observable<OhhProviderReview> {
        return this.providerApiService.updateReview(review);
    }

    deleteReview(reviewId: string): Observable<OhhProviderReview> {
        return this.providerApiService.deleteReview(reviewId);
    }
}
