import LanguageCode = OhhEnums.LanguageCode;

import { finalize, forkJoin, Observable, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { KeyManagerService } from '../../api/key/key-manager.service';
import localeEs from '@angular/common/locales/es';
import { OhhEnums } from '../enumerations/ohh.enums';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AppInitializerService {
    // private authManager: AuthManagerService;

    constructor(private keyManager: KeyManagerService,
                private ohhUserManager: OhhUserManagerService,
                private translateService: TranslateService,
    ) {
    }

    load() {
        return new Promise((resolve, reject) => {
            this.translateService.addLangs([
                LanguageCode.English_US,
                // LanguageCode.Spanish, LanguageCode.French, LanguageCode.German, LanguageCode.Italian, LanguageCode.Dutch,
                // LanguageCode.Korean, LanguageCode.Thai, LanguageCode.Japanese
                // LanguageCode.Portuguese, LanguageCode.Swedish, LanguageCode.Chinese_Simplified,
                // LanguageCode.Vietnamese, LanguageCode.Russian, LanguageCode.Bulgarian, LanguageCode.Danish, LanguageCode.Norwegian, LanguageCode.Czech
            ]);
            this.translateService.setDefaultLang(LanguageCode.English_US);

            // This allows for proper usage of datepipe to transform dateformat in supported locales.
            this.registerLocales();

            // We need to wait for the strings to be loaded, too. Since we don't yet know which of the supported languages
            // will be used, we load them all.
            const todo: Observable<any>[] = [
                this.keyManager.getApiKey(),
                ...this.translateService.langs.map((lang) => this.translateService.getTranslation(lang))
            ];

            // Do all the retrievals and resolve our promise when complete.
            forkJoin(todo)
                .subscribe(([apiKey]) => {
                    if (apiKey == null) {
                        resolve(false);
                    }

                    // Try to get the current user before moving on. This will properly set the user's login state, avatar, etc.
                    // If we don't have one, no worries, it just means no user is logged in.
                    this.ohhUserManager.getUserByToken()
                        .pipe(finalize(() => {
                            resolve(true);
                        }))
                        .subscribe();
                });
        });
    }

    // Method to register locales. Will be used by Datepipe.
    registerLocales() {
        registerLocaleData(localeEs);
        // registerLocaleData(localeFr);
        // registerLocaleData(localeDe);
        // registerLocaleData(localeIt);
        // registerLocaleData(localeNl);
        // registerLocaleData(localeKo);
        // registerLocaleData(localeTh);
        // registerLocaleData(localeJa);
    }

}
