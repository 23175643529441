import { Injectable } from '@angular/core';
import { MarComApiService } from './mar-com-api.service';
import { MarComRecord } from './models/mar-com-record.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MarComManagerService {

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private marComApiService: MarComApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    upsertMarComRecord(record: MarComRecord): Observable<MarComRecord> {
        return this.marComApiService.upsertMarComRecord(record);

    }
}
