import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class KeyApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getApiKey(): Observable<string> {
        return this.apiService.apiRequest<any>(KeyApiService.getApiKeyUrl, HttpMethod.Get)
            .pipe(map((response: any) => response.apiKey));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}keys`; }

    private static get getApiKeyUrl(): string { return `${KeyApiService.baseUrl}/apiKey`; }
}
