import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';
import { ComponentBase } from '../../shared/misc/component-base';
import { OhhEnums } from '../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../common/services/ohh-message.service';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';
import { Router } from '@angular/router';
import MessageSeverity = OhhEnums.MessageSeverity;

@UntilDestroy()
@Component({
    selector: 'ohh-pro-sign-up-basic',
    templateUrl: './pro-sign-up-basic.component.html',
    styleUrl: './pro-sign-up-basic.component.less'
})
export class ProSignUpBasicComponent extends ComponentBase implements OnInit {

    protected isLoggedIn = false;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected override broadcastService: BroadcastService,
                private messageService: OhhMessageService,
                private ohhUserManager: OhhUserManagerService,
                private router: Router
    ) {
        super(broadcastService);
    }

    override ngOnInit() {
        super.ngOnInit();

        this.ohhUserManager.userLogInStateChanged
            .pipe(untilDestroyed(this))
            .subscribe(() => this.isLoggedIn = this.ohhUserManager.ohhUser != null);

        setTimeout(() => {
            this.isLoggedIn = this.ohhUserManager.ohhUser != null
        });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onBecomeAProvider() {
        this.ohhUserManager.setAsProvider(this.ohhUserManager.ohhUser.id)
            .subscribe({
                next: isProvider => {
                    if (isProvider == true) {
                        this.broadcastService.providerStatusChanged.next(isProvider);
                        setTimeout(() => this.router.navigate([`/providers/account`], { queryParams: { newProvider: true } }));
                    }
                },
                error: err => {
                    // TODO: Alert the user. Log Something?
                    this.messageService.showMessage("Account Error", err?.error?.Error_1[0], MessageSeverity.Error);
                }
            })
    }

    protected onNavigate(route: string) {
        this.router.navigate([`${route}`], { queryParams: { isProviderSignup: true } });
    }
}
