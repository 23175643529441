<div id="ol-provider-card-container" [ngClass]="{'is-mobile': isMobile}">

	<ng-container *ngIf="!isMobile" [ngTemplateOutlet]="desktopView"></ng-container>

	<ng-container *ngIf="isMobile" [ngTemplateOutlet]="mobileView"></ng-container>
	<div id="ol-mpc-shield" *ngIf="isMobile" (click)="onButtonClick()"></div>
</div>

<ng-template #cardImage>
	<div id="ol-provider-image" class="prevent-select" [ngClass]="{'is-demo': isDemo}" [ngStyle]="{'background-image': 'url(' + imagePath + ')'}"></div>
</ng-template>

<ng-template #rating>
	<div id="ol-provider-rating" class="prevent-select" *ngIf="ratingIcons?.length > 0;">
		<fa-icon *ngFor="let x of ratingIcons; let i = index" [icon]="ratingIcons[i]"></fa-icon>
	</div>
</ng-template>

<ng-template #rateInfo>
	<span *ngIf="contactForRate == true" class="ol-provider-cv-info ol-ellipsis-text cfr">
  		{{ 'STRINGS.CONTACT_FOR_RATE' | translate }}
	</span>
	<span *ngIf="contactForRate == false" class="ol-provider-cv-info ol-ellipsis-text">
  		${{ provider?.providerInfo?.rate }}<span *ngIf="rateUnit != null">/{{ rateUnit | translate }}</span>
	</span>
</ng-template>

<ng-template #desktopView>
	<div id="ol-provider-first-row">
		<!--		<div id="ol-provider-image" class= "prevent-select" [ngClass]="{'is-demo': isDemo}" [ngStyle]="{'background-image': 'url(' + imagePath + ')'}"></div>-->
		<ng-container [ngTemplateOutlet]="cardImage"></ng-container>

		<div id="ol-p-name-container" class="prevent-select">
			<span id="ol-provider-cv-name" class="ol-ellipsis-text">{{ providerName }}</span>
			<ng-container [ngTemplateOutlet]="rating"></ng-container>
		</div>

		<div id="ol-provider-cv" class="prevent-select">
			<div id="ol-provider-cv-left">
				<span class="ol-provider-cv-info ol-ellipsis-text">{{ provider?.providerInfo?.service }}</span>
				<ng-container [ngTemplateOutlet]="rateInfo"></ng-container>
			</div>
			<div id="ol-provider-cv-right">
				<div class="ol-provider-badge">
					<fa-icon icon="circle-check" [ngClass]="{'has-attr': provider?.providerInfo?.isVerified}"></fa-icon>
					<div>{{ 'STRINGS.VERIFIED' | translate }}</div>
				</div>
				<div class="ol-provider-badge">
					<fa-icon icon="circle-check" [ngClass]="{'has-attr': provider?.providerInfo?.isInsured}"></fa-icon>
					<div>{{ 'STRINGS.INSURED' | translate }}</div>
				</div>
			</div>
		</div>
	</div>

<!--	<div id="ol-provider-details" class="prevent-select">-->
<!--		<div id="ol-provider-intro">-->
<!--			<span id="ol-provider-intro-body"><strong>{{ 'STRINGS.INTRODUCTION' | translate }}: </strong>{{ provider?.providerInfo?.introduction }}</span>-->
<!--		</div>-->
<!--	</div>-->

	<div id="ol-provider-details" class="prevent-select">
		<div id="ol-provider-intro">
			<span id="ol-provider-intro-body">
				<strong>{{ 'STRINGS.INTRODUCTION' | translate }}: </strong>
				<span [innerHTML]="provider?.providerInfo?.introduction"></span>
			</span>
		</div>
	</div>

	<div id="ol-provider-specialties">
		<div id="olps-title" class="prevent-select"><strong>{{ 'STRINGS.SPECIALTIES' | translate }}:</strong></div>
		<div id="ol-provider-chips">
			<div class="ol-scroll-container">
				<div id="ol-chip" class="prevent-select" *ngFor="let s of provider?.providerInfo?.specialties">{{ s }}</div>
			</div>
		</div>
	</div>

	<div id="ol-provider-btn" class="ol-btn" (click)="onButtonClick()">
		<span class="  ol-green-btn-text">{{ buttonText | translate }}</span>
	</div>
</ng-template>

<ng-template #mobileView>
	<div class="ol-mpc-row">
		<div class="ol-mpc-rc r1">
			<ng-container [ngTemplateOutlet]="cardImage"></ng-container>
		</div>
		<div class="ol-mpc-rc r2">
			<div class="ol-scroll-container">
				<div class="ol-ellipsis-text">{{ providerName }}</div>
				<div class="ol-provider-cv-info ol-ellipsis-text">{{ provider?.providerInfo?.service }}</div>
			</div>
		</div>
    <div class="ol-mp-rc r3">
      <ng-container [ngTemplateOutlet]="rateInfo"></ng-container>
    </div>
	</div>
	<div class="ol-mpc-row row2">
		<ng-container [ngTemplateOutlet]="rating"></ng-container>
	</div>
	<div class="ol-mpc-row row3">
		<div class="ol-scroll-container">
			<div class="ol-ellipsis-text">{{ provider?.providerInfo?.introduction }}</div>
		</div>
	</div>
</ng-template>
