import MessageSeverity = OhhEnums.MessageSeverity;

import { AccessToken, TokenCredential } from '@azure/identity';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { AzureManagerService } from '../../api/azure/azure-manager.service';
import { environment } from '../../../environments/environment';
import { ErrorLoggingService } from './error-logging.service';
import { Injectable } from '@angular/core';
import { OhhEnums } from '../enumerations/ohh.enums';
import { OhhMessageService } from './ohh-message.service';

class TokenCredentialImpl implements TokenCredential {
    constructor(private token: string) {}

    getToken(): Promise<AccessToken | null> {
        return Promise.resolve({
            token: this.token,
            expiresOnTimestamp: Date.now() + 3600 * 1000 // Token expiration timestamp
        });
    }
}

@Injectable({
    providedIn: 'root'
})
export class AzureBlobService {

    private readonly USER_IMAGES_ROOT = "user-images";

    private containerClient: ContainerClient;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(private azureManager: AzureManagerService,
                private logger: ErrorLoggingService,
                private messageService: OhhMessageService
    ) {
        // Initialize with your storage account name and container name
        const accountName = environment.ohhBlobStorageAccount;
        const containerName = environment.ohhBlobStorageContainer;

        this.azureManager.getToken()
            .subscribe(token => {
                const blobServiceClient = new BlobServiceClient(
                    `https://${accountName}.blob.core.windows.net`,
                    new TokenCredentialImpl(token.token)
                );
                this.containerClient = blobServiceClient.getContainerClient(containerName);
            }, error => {
                console.log();
            });
    }

    // =========================================================================================================================================================
    //
    // =========================================================================================================================================================

    // Method to upload a blob
    async uploadBlob(blobName: string, content: Blob, handler: () => void): Promise<void> {
        try {
            const blockBlobClient = this.containerClient.getBlockBlobClient(`${this.USER_IMAGES_ROOT}/${blobName}`);
            await blockBlobClient
                .uploadData(content, { blobHTTPHeaders: { blobContentType: content.type } })
                .then(() => handler())
        }
        catch (error) {
            this.messageService.showMessage("Error Uploading Image", 'Error uploading image. Please try again.', MessageSeverity.Error);
            this.logger.handleError(new Error('Error uploading image.', { cause: error }));
        }

    }

    // Method to download a blob
    async downloadBlob(blobName: string): Promise<Blob> {
        try {

            const blockBlobClient = this.containerClient.getBlockBlobClient(`${this.USER_IMAGES_ROOT}/${blobName}`);
            const downloadResponse = await blockBlobClient.download();
            return await downloadResponse.blobBody;
        }
        catch (ex) {
            console.log();
            return null;
        }
    }

    // Method to delete a blob
    async deleteBlob(blobName: string, handler: () => void): Promise<void> {
        const blockBlobClient = this.containerClient.getBlockBlobClient(`${this.USER_IMAGES_ROOT}/${blobName}`);
        await blockBlobClient.delete().then(() => handler());
    }
}
