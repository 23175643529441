<div id="ol-log-in-container" class="ol-page-container">
    <ohh-full-vw-constrained-container [isPadded]="true">
        <ohh-section-header>
            <div class="ol-sh-heading">
                <span><strong>LOG IN</strong></span>
            </div>
            <div id="ol-sub-text" >
                <span *ngIf="isNewUser">Please log in to <em>{{'OHH.APP_NAME' | translate }}</em> for the first time.</span>

<!--                <span> Logging In to <em>{{'OHH.APP_NAME' | translate }}</em> will give you access to Provider Details.</span>-->

<!--                <div *ngIf="isNewUser">-->
<!--                    The first time you log in to <em>{{'OHH.APP_NAME' | translate }}</em> we ask that you complete your profile so that we may better assist you.-->
<!--                </div>-->
            </div>
        </ohh-section-header>

        <div id="ol-sign-in-wrapper">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div id="ol-sign-in-container">

                    <div id="ol-email-pw-container">
                        <div class="ol-labeled-input-text">
                            <label for="email">Email Address</label>
                            <input pInputText id="email" type="email" formControlName="email">
                        </div>
                        <div class="ol-labeled-input-text">
                            <label for="password">Password</label>
                            <input type="password" pInputText id="password" formControlName="password">
                        </div>

						<div *ngIf="!suppressEmail" style="display: flex; justify-content: flex-end">
							<span class="ol-pseudo-link" (click)="onForgotPassword()">Forgot password?</span>
						</div>
                    </div>

                    <p *ngIf="emailIsInvalid || passwordIsInvalid" class="ol-field-error">
                        Email or password information is invalid.
                    </p>

                    <div id="ol-btn-container">
                        <button type="submit" class="ol-green-button">Submit</button>
                    </div>

                </div>

            </form>

            <div>
                Don't have an account? <span class="ol-pseudo-link" (click)="onSignUp()">Sign up</span>.
            </div>

			<div *ngIf="unverifiedUserEmail != null && !suppressEmail" id="ol-resend-email-conf" >
				<div>{{ 'PHRASE.YOU_MUST_VERIFY_YOUR_EMAIL' | translate }}</div>
				<div class="ol-pseudo-link" (click)="onSendEmailVerification()">{{ 'PHRASE.RESEND_VERIFICATION_EMAIL' | translate }}</div>
			</div>

            <ohh-waiting-indicator *ngIf="isSigningIn" [backgroundColor]="'#c8c8c81A'" [blurBackground]="true"></ohh-waiting-indicator>
        </div>

    </ohh-full-vw-constrained-container>
</div>
