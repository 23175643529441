export class StripeCreateSession {

    constructor(userId: string, email: string, name: string) {
        this.userId = userId;
        this.email = email;
        this.name = name;
    }

    userId: string;
    name: string;
    email: string;

}
