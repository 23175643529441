import EmailType = OhhEnums.EmailType;
import PaymentResponseType = OhhEnums.PaymentResponseType;

import { RouterModule, Routes } from '@angular/router';
import { ConfirmEmailComponent } from './core/confirm-email/confirm-email.component';
import { customerGuard } from './common/guards/customer.guard';
import { EarlySignUpComponent } from './core/early-sign-up/early-sign-up.component';
import { EmailSentComponent } from './core/reset-password-email-sent/email-sent.component';
import { HomeComponent } from './root-components/home/home.component';
import { LogInComponent } from './core/log-in/log-in.component';
import { NeighborhoodComponent } from './core/neighborhood/neighborhood.component';
import { NgModule } from '@angular/core';
import { notProviderGuard } from './common/guards/not-provider.guard';
import { OhhEnums } from './common/enumerations/ohh.enums';
import { PaymentComponent } from './core/payment/payment.component';
import { PaymentResponseComponent } from './core/payment/payment-response/payment-response.component';
import { PendingConfirmationComponent } from './core/pending-confirmation/pending-confirmation.component';
import { ProSignUpBasicComponent } from './core/pro-sign-up-basic/pro-sign-up-basic.component';
import { ProviderDetailComponent } from './core/provider-detail/provider-detail.component';
import { ProviderSignUpComponent } from './core/pro-sign-up/provider-sign-up.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { ServiceCategoriesComponent } from './core/service-categories/service-categories.component';
import { ServicesComponent } from './core/services/services.component';
import { unsavedChangesGuard } from './common/guards/unsaved-changes.guard';
import { UserAccountComponent } from './core/user-account/user-account.component';
import { UserRegistrationComponent } from './core/user-registration/user-registration.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'services',
        // canActivate: [AuthGuardService],
        component: ServicesComponent
    },
    {
        path: 'service-categories',
        component: ServiceCategoriesComponent
    },
    {
        path: 'register',
        component: UserRegistrationComponent
    },
    {
        path: 'provider-sign-up',
        canActivate: [notProviderGuard],
        component: ProviderSignUpComponent
    },
    {
        path: 'pro-sign-up',
        canActivate: [notProviderGuard],
        component: ProSignUpBasicComponent
    },
    {
        path: 'neighborhood',
        component: NeighborhoodComponent,
    },
    {
        path:'early-sign-up',
        canActivate: [notProviderGuard],
        component: EarlySignUpComponent
    },
    {
        path:'log-in',
        component: LogInComponent
    },
    {
        path: 'confirm-email',
        component: ConfirmEmailComponent
    },
    {
        path: 'pending-confirmation',
        component: PendingConfirmationComponent
    },
    {
        path: 'reset-pw-email-sent',
        component: EmailSentComponent,
        data: { text: 'PHRASE.EMAIL_SENT_RESET', emailType: EmailType.PasswordReset }
    },
    {
        path: 'verify-email-sent',
        component: EmailSentComponent,
        data: { text: 'PHRASE.EMAIL_SENT_VERIFY', emailType: EmailType.EmailVerify }
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        path:'customer/profile',
        canActivate: [customerGuard],
        canDeactivate: [unsavedChangesGuard],
        component: UserAccountComponent
    },
    {
        path:'providers/:providerId/detail',
        component: ProviderDetailComponent
    },
    {
        path:'providers/account',
        canActivate: [customerGuard],
        canDeactivate: [unsavedChangesGuard],
        component: UserAccountComponent,
        data: {
            isProvider: true
        }
    },
    {
        path: 'payments',
        component: PaymentComponent
    },
    {
        path: 'payment-success',
        component: PaymentResponseComponent,
        data: {
            responseType: PaymentResponseType.Success
        }
    },
    {
        path: 'payment-cancel',
        component: PaymentResponseComponent,
        data: {
            responseType: PaymentResponseType.Cancel
        }
    },
    {
        path:'company',
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
    },
    {
        path:'help-center',
        loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule)
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
