import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  // General
  showFooter = new Subject<boolean>();
  showMegaMenu = new Subject<boolean>();
  showMobileMenu = new Subject<boolean>();
  hideMobileMenu = new Subject();
  scrollToTop = new Subject();
  cancelComponentChanges = new Subject<string>();
  providerStatusChanged = new Subject<boolean>();

  // User
  profilePictureChanged = new Subject<{ uri: string }>();

}
