import { Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { KeyApiService } from './key-api.service';

@Injectable({
    providedIn: 'root'
})
export class KeyManagerService {

    private _apiKey: string  = null;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private keyApiService: KeyApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getApiKey(): Observable<string> {
        return this.keyApiService.getApiKey()
            .pipe(tap((key: string) => this._apiKey = key));
    }

    get apiKey(): string {
        return this._apiKey;
    }
}
