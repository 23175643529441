<p-dialog [visible]="true" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center"
		  [style]="{maxWidth: '95%', maxHeight: '95%'}"
		  [contentStyle]="{width: '100%', height: '100%'}"
>
	<p-header class="rsd-header">
		{{ title | translate | titlecase }}
	</p-header>

	<ohh-review-writer
			[isAdminReview]="isAdminReview"
			[providerId]="providerId"
			[review]="review"
			(reviewSaved)="onReviewSaved($event)"
			(cancel)="onCancel()"
	></ohh-review-writer>

</p-dialog>
