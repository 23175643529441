import MessageSeverity = OhhEnums.MessageSeverity;

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../../common/services/ohh-message.service';
import { OhhProviderReview } from '../../../api/provider/models/ohh-provider-review.model';
import { OhhUserManagerService } from '../../../api/ohh-user/ohh-user-manager.service';
import { ReviewManagerService } from '../../../api/review/review-manager.service';

@Component({
    selector: 'ohh-review-viewer',
    templateUrl: './review-viewer.component.html',
    styleUrl: './review-viewer.component.less'
})
export class ReviewViewerComponent implements OnInit {
    @Output() reviewEdited = new EventEmitter<OhhProviderReview>();
    @Output() reviewDeleted = new EventEmitter<OhhProviderReview>();

    @Input() heightInPx = 300;
    @Input() maxWidthInPx: number = null;
    @Input() reviews: OhhProviderReview[] = [];

    private _currentUserId: string;
    @Input() set currentUserId(value: string) {
        this._currentUserId = value;
    }

    get currentUserId(): string {
        return this._currentUserId;
    }

    protected showReviewDialog = false;
    protected reviewToEdit: OhhProviderReview;
    protected allowAdminReview = false;
    protected isUserAdmin = false;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private ohhUserManager: OhhUserManagerService,
                private messageService: OhhMessageService,
                private reviewManager: ReviewManagerService,
    ) {}

    ngOnInit() {
        this.allowAdminReview = environment.allowAdminReviews;
        this.isUserAdmin = this.ohhUserManager.isUserLoggedIn && this.ohhUserManager.isUserAdmin;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onReviewEdited(review: OhhProviderReview) {
        this.reviewToEdit = review;
        this.showReviewDialog = true;
    }

    onReviewSaved(review: OhhProviderReview) {
        this.reviewEdited.next(review);
        this.showReviewDialog = false;
    }

    onDeleteReview(review: OhhProviderReview) {
        this.reviewManager.deleteReview(review.id)
            .subscribe({
                next: (response: OhhProviderReview)=> {
                    this.reviewDeleted.next(review);
                    this.messageService.showMessage("Success!", "Selected review was successfully deleted.", MessageSeverity.Success);
                },
                error: err => {
                    this.messageService.showMessage("Error", "Selected review could not be deleted. Please try again.", MessageSeverity.Error);
                }
            })
    }
}
