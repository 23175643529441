import { Observable, of, Subject, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';
import { catchError } from 'rxjs/operators';
import { CreateOhUserModel } from './models/create-ohh-user.model';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalCacheService } from '../../common/services/local-cache.service';
import { OhhProviderReview } from '../provider/models/ohh-provider-review.model';
import { OhhUser } from './models/ohh-user.model';
import { OhhUserApiService } from './ohh-user-api.service';

enum TokenKey {
    Sid = 'sid'
}

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class OhhUserManagerService {
    // Subjects
    userLogInStateChanged = new Subject<OhhUser>();

    private _ohhUser: OhhUser;
    private token: string;
    private _decodedToken: string;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private broadcastService: BroadcastService,
                private ohhUserApiService: OhhUserApiService,
                private localCacheService: LocalCacheService,
    ) {
        this.broadcastService.providerStatusChanged
            .pipe(untilDestroyed(this))
            .subscribe((isProvider: boolean) => this.ohhUser.isProvider = isProvider);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    set ohhUser(value: OhhUser) {
        this._ohhUser = value;
        this.userLogInStateChanged.next(this._ohhUser);
    }

    get ohhUser(): OhhUser {
        return this._ohhUser;
    }

    get isUserLoggedIn(): boolean {
        return this.ohhUser != null;
    }

    logout(): void {
        this.ohhUser = null;
        this._decodedToken = null;
        this.localCacheService.ohhApiAccessToken = null;
        this.localCacheService.ohhApiRefreshToken = null;

        this.userLogInStateChanged.next(null);
    }

    createUser(userModel: CreateOhUserModel): Observable<OhhUser> {
        return this.ohhUserApiService.createUser(userModel);
    }

    updateUser(userModel: CreateOhUserModel): Observable<OhhUser> {
        return this.ohhUserApiService.updateUser(userModel);
    }

    getUserById(id: string): Observable<OhhUser> {
        return this.ohhUserApiService.getUserById(id)
            .pipe(
                tap((ohhUser: OhhUser) => this.ohhUser = ohhUser),
                catchError(err => {
                    this.ohhUser = null;
                    throw err;
                })
            );
    }

    setAsProvider(userId: string): Observable<boolean> {
        return this.ohhUserApiService.setAsProvider(userId);
    }

    getReviewsWrittenByUser(userId: string): Observable<OhhProviderReview[]> {
        return this.ohhUserApiService.getReviewsWrittenByUser(userId);
    }

    getReviewsWrittenByUserForProvider(userId: string, providerId: string): Observable<OhhProviderReview> {
        return this.ohhUserApiService.getReviewsWrittenByUserForProvider(userId, providerId);
    }

    getUserByToken(token: string = null): Observable<OhhUser> {
        this.token = token || this.localCacheService.ohhApiAccessToken;
        if (this.token == null || this.token == '') return of(null);

        return this.getUserById(this.sid);
    }

    get sid(): string {
        return this.parseTokenValue(TokenKey.Sid);
    }

    get isUserAdmin(): boolean {
        return this.decodedToken != null && this.decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].includes('Admin')
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private get decodedToken(): any {
        if (!this._decodedToken) this._decodedToken = this.token ? new JwtHelperService().decodeToken(this.token) : null;

        return this._decodedToken;
    }

    private parseTokenValue(value: string): string {
        return this.decodedToken ? this.decodedToken[value] || '' : '';
    }

}
