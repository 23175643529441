import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentsApiService } from './payments-api.service';
import { StripeChargeRequest } from './models/stripe-charge-request.model';
import { StripeCreateSession } from './models/stripe-create-session.model';

@Injectable({
    providedIn: 'root'
})
export class PaymentsManagerService {

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private paymentsApiService: PaymentsApiService) { }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    createRemoteCheckoutSession(createSessionInfo: StripeCreateSession) : Observable<any> {
        return this.paymentsApiService.createRemoteCheckoutSession(createSessionInfo);
    }

    createEmbeddedCheckoutSession(createSessionInfo: StripeCreateSession) : Observable<any> {
        return this.paymentsApiService.createEmbeddedCheckoutSession(createSessionInfo);
    }
}
