<div id="ol-provider-detail-container" class="ol-page-container">

    <ohh-full-vw-constrained-container [sidePadding]="20">

        <div id="olpd-biz-deets">

            <div id="olpd-img-container"
                 [ngClass]="{'placeholder': profileImageUri == null}"
                 [ngStyle]="{'background-image': 'url(' + (profileImageUri == null ? placeholderImageUrl : profileImageUri) + ')'}"
				 (click)="onAdminReview($event)"
            ></div>

            <div id="olpd-biz-summary">
                <div id="olpd-name" class="prevent-select">{{ provider?.firstName }} {{ provider?.lastName }}</div>
                <div id="olpd-biz-name" class="prevent-select">{{ provider?.providerInfo?.businessName || provider?.providerInfo?.professionalTitle }}</div>
                <div id="olpd-service" class="prevent-select">{{ provider?.providerInfo?.service }}</div>
                <div id="olpd-rate" class="prevent-select">
                    <span *ngIf="!provider?.providerInfo?.contactForRate && provider?.providerInfo?.rate">${{ provider?.providerInfo.rate }}/hr</span>
                    <span *ngIf="provider?.providerInfo?.contactForRate">{{ 'STRINGS.CONTACT_FOR_RATE' | translate }}</span>
                </div>
                <div id="ol-provider-cv-right">
                    <div class="ol-provider-badge prevent-select">
                        <fa-icon icon="circle-check" [ngClass]="{'has-attr': provider?.providerInfo?.isVerified}"></fa-icon>
                        <div>{{ 'STRINGS.VERIFIED' | translate }}</div>
                    </div>
                    <div class="ol-provider-badge prevent-select">
                        <fa-icon icon="circle-check" [ngClass]="{'has-attr': provider?.providerInfo?.isInsured}"></fa-icon>
                        <div>{{ 'STRINGS.INSURED' | translate }}</div>
                    </div>
					<div *ngIf="userCanLeaveReview" id="olpd-review-link">
						<button type="submit" class="ol-green-button inverted" (click)="onLeaveReview()">{{ 'CASE_SENSITIVE.LEAVE_REVIEW' | translate }}</button>
					</div>
                </div>
            </div>

            <div id="olpd-contact-info">
                <div>
                    <div class="olpd-contact-item">
                        <div class="olpd-ci-icon prevent-select">
                            <fa-icon icon="phone"></fa-icon>
                        </div>
                        <div>
                            <div class="olpd-ci-label prevent-select">PHONE</div>
                            <div *ngIf="currentUserId != null" class="olpd-ci-value">
                                <a *ngIf="cleanPhoneNum != null" [href]="'tel:' + cleanPhoneNum">{{ provider?.providerInfo?.businessPhone }}</a>
                                <span *ngIf="cleanPhoneNum == null">--</span>
                            </div>
							<div *ngIf="currentUserId == null" class="olpd-ci-value">
								<p-skeleton width="150px" height="20px"></p-skeleton>
							</div>
                        </div>
                    </div>
                    <div class="olpd-contact-item">
                        <div class="olpd-ci-icon prevent-select">
                            <fa-icon icon="globe"></fa-icon>
                        </div>
                        <div>
                            <div class="olpd-ci-label prevent-select">WEBSITE</div>
                            <div *ngIf="currentUserId != null" class="olpd-ci-value">
                                <a *ngIf="provider?.providerInfo?.websiteUrl != null" [href]="provider?.providerInfo?.websiteUrl" target="_blank">{{ provider?.providerInfo?.websiteUrl }}</a>
                                <span *ngIf="provider?.providerInfo?.websiteUrl == null">--</span>
                            </div>
							<div *ngIf="currentUserId == null" class="olpd-ci-value">
								<p-skeleton width="200px" height="20px"></p-skeleton>
							</div>
                        </div>
                    </div>
                    <div class="olpd-contact-item">
                        <div class="olpd-ci-icon prevent-select">
                            <fa-icon icon="envelope"></fa-icon>
                        </div>
                        <div>
                            <div class="olpd-ci-label prevent-select">EMAIL</div>
                            <div *ngIf="currentUserId != null" class="olpd-ci-value">
                                <a *ngIf="provider?.providerInfo?.businessEmail != null" [href]="'mailto:' + provider?.providerInfo?.businessEmail">
                                    {{ provider?.providerInfo?.businessEmail }}
                                </a>
                                <span *ngIf="provider?.providerInfo?.businessEmail == null">--</span>
                            </div>
							<div *ngIf="currentUserId == null" class="olpd-ci-value center">
								<p-skeleton width="200px" height="20px"></p-skeleton>
							</div>
                        </div>
                    </div>

<!--					<div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; backdrop-filter: blur(2px);"></div>-->
                </div>

				<div class="olpd-sl-header prevent-select">SOCIAL LINKS</div>
                <div *ngIf="currentUserId != null" class="olpd-social-links">
                    <a *ngIf="provider?.providerInfo?.instaUrl != null && provider?.providerInfo?.instaUrl != ''" [href]="provider?.providerInfo?.instaUrl" target="_blank">
                        <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
                    </a>
                    <a *ngIf="provider?.providerInfo?.facebookUrl != null && provider?.providerInfo?.facebookUrl != ''" [href]="provider?.providerInfo?.facebookUrl" target="_blank">
                        <fa-icon [icon]="['fab', 'facebook-f']"></fa-icon>
                    </a>
                    <a *ngIf="provider?.providerInfo?.linkedInUrl != null && provider?.providerInfo?.linkedInUrl != ''" [href]="provider?.providerInfo?.linkedInUrl" target="_blank">
                        <fa-icon [icon]="['fab', 'linkedin-in']"></fa-icon>
                    </a>
					<a *ngIf="provider?.providerInfo?.youTubeUrl != null && provider?.providerInfo?.youTubeUrl != ''" [href]="provider?.providerInfo?.youTubeUrl" target="_blank">
						<fa-icon [icon]="['fab', 'youtube']"></fa-icon>
					</a>
					<a *ngIf="provider?.providerInfo?.tikTokUrl != null && provider?.providerInfo?.tikTokUrl != '' " [href]="provider?.providerInfo?.tikTokUrl" target="_blank">
						<fa-icon [icon]="['fab', 'tiktok']"></fa-icon>
					</a>
                </div>

				<div *ngIf="currentUserId == null" class="olpd-social-links prevent-select">
					<p-skeleton width="30px" height="30px" *ngFor="let l of 4 | fill"></p-skeleton>
				</div>
				<span *ngIf="currentUserId == null" id="login-4-ci" class="prevent-select">Please log in to see contact information.</span>
            </div>

        </div>

        <div id="olpd-gallery-container" *ngIf="provider?.galleryImages.length > 0">
            <p-carousel
                    [value]="provider?.galleryImages"
                    [numVisible]="3"
                    [numScroll]="3"
                    [responsiveOptions]="responsiveOptions"
                    [showIndicators]="false"
                    styleClass="ol-provider-carousel"
            >
                <ng-template let-image pTemplate="item">
                    <div id="olpd-car-img-container" class="prevent-selectX">
                        <p-image
                                [src]="ohhCdnUrl + '/' + image.blobName"
                                [preview]="true"
                                [loading]="'eager'"
                                [style]="{'width': '100%', 'height': '100%', 'object-fit': 'cover'}"
                                [imageStyle]="{'width': '100%', 'height': '100%', 'object-fit': 'cover'}"
                                appendTo="body"
                        ></p-image>
                    </div>
                </ng-template>
            </p-carousel>
        </div>

        <div id="olpd-body-container" class="prevent-selectX">
            <div class="olpd-body-col">
                <div class="olpd-body-section">
                    <div class="olpd-body-header prevent-select">Introduction</div>
                    <div class="olpd-body-content">
                        <ohh-read-more [defaultHeight]="readMoreDefaultHeight">
							<div [innerHTML]="provider?.providerInfo?.introduction"></div>
<!--                            {{ provider?.providerInfo?.introduction }}-->
                        </ohh-read-more>
                    </div>
                </div>

                <div class="olpd-body-section">
                    <div class="olpd-body-header prevent-select">Experience</div>
                    <div class="olpd-body-content">
                        <ohh-read-more [defaultHeight]="readMoreDefaultHeight">
							<div [innerHTML]="provider?.providerInfo?.experience"></div>
<!--                            {{ provider?.providerInfo?.experience }}-->
                        </ohh-read-more>
                    </div>
                </div>

                <div class="olpd-body-section">
                    <div class="olpd-body-header prevent-select">What I Do</div>
                    <div class="olpd-body-content">
                        <ohh-read-more [defaultHeight]="readMoreDefaultHeight">
							<div [innerHTML]="provider?.providerInfo?.services"></div>
<!--                            {{ provider?.providerInfo?.services }}-->
                        </ohh-read-more>
                    </div>
                </div>

                <div class="olpd-body-section">
                    <div class="olpd-body-header prevent-select">Why Hire Me</div>
                    <div class="olpd-body-content">
                        <ohh-read-more [defaultHeight]="readMoreDefaultHeight">
                            <div [innerHTML]="provider?.providerInfo?.whyHire"></div>
                        </ohh-read-more>
                    </div>
                </div>
            </div>

            <div class="olpd-body-col">

                <div class="olpd-body-section" *ngIf="reviews?.length > 0">
                    <div class="olpd-body-header prevent-select">Reviews</div>
                    <ohh-review-viewer
							[currentUserId]="currentUserId"
                            [reviews]="reviews"
							(reviewEdited)="onReviewEdited($event)"
							(reviewDeleted)="onReviewDeleted($event)"
                    ></ohh-review-viewer>

                </div>

                <div class="olpd-body-section" *ngIf="specialties?.length > 0">
                    <div class="olpd-body-header prevent-select">Specialties</div>
                    <div class="olpd-body-content">
                        <div class="olpd-list-col">
                            <ng-container *ngFor="let s of specialties; let i = index">
                                <ohh-provider-icon-list-card
                                        *ngIf="i % 3 == 0"
                                        [name]="s.name"
                                        [icon]="'circle'"
                                        [iconSizePx]="11"
                                ></ohh-provider-icon-list-card>
                            </ng-container>
                        </div>

                        <div class="olpd-list-col">
                            <ng-container *ngFor="let s of specialties; let i = index">
                                <ohh-provider-icon-list-card
                                        *ngIf="i % 3 == 1"
                                        [name]="s.name"
                                        [icon]="'circle'"
                                        [iconSizePx]="11"
                                ></ohh-provider-icon-list-card>
                            </ng-container>
                        </div>

                        <div class="olpd-list-col">
                            <ng-container *ngFor="let s of specialties; let i = index">
                                <ohh-provider-icon-list-card
                                        *ngIf="i % 3 == 2"
                                        [name]="s.name"
                                        [icon]="'circle'"
                                        [iconSizePx]="11"
                                ></ohh-provider-icon-list-card>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="olpd-body-section" *ngIf="locations?.length > 0">
                    <div class="olpd-body-header prevent-select">Locations</div>

                    <ohh-read-more [defaultHeight]="readMoreDefaultHeight">
                        <div class="olpd-body-content">
                            <div class="olpd-list-col">
                                <ng-container *ngFor="let s of locations; let i = index">
                                    <ohh-provider-icon-list-card
                                            *ngIf="i % 3 == 0"
                                            [name]="s.name"
                                            [icon]="'location-dot'"
                                            [iconSizePx]="14"
                                    ></ohh-provider-icon-list-card>
                                </ng-container>
                            </div>
                            <div class="olpd-list-col">
                                <ng-container *ngFor="let s of locations; let i = index">
                                    <ohh-provider-icon-list-card
                                            *ngIf="i % 3 == 1"
                                            [name]="s.name"
                                            [icon]="'location-dot'"
                                            [iconSizePx]="14"
                                    ></ohh-provider-icon-list-card>
                                </ng-container>
                            </div>
                            <div class="olpd-list-col">
                                <ng-container *ngFor="let s of locations; let i = index">
                                    <ohh-provider-icon-list-card
                                            *ngIf="i % 3 == 2"
                                            [name]="s.name"
                                            [icon]="'location-dot'"
                                            [iconSizePx]="14"
                                    ></ohh-provider-icon-list-card>
                                </ng-container>
                            </div>
                        </div>
                    </ohh-read-more>
                </div>

                <div class="olpd-body-section" *ngIf="contactMethods?.length > 0">
                    <div class="olpd-body-header prevent-select">Contact Methods</div>
                    <div class="olpd-body-content">

                        <div class="olpd-list-col">
                            <ng-container *ngFor="let p of contactMethods; let i = index">
                                <ohh-provider-icon-list-card
                                        *ngIf="i % 3 == 0"
                                        [name]="p.name"
                                        [icon]="p.icon"
                                        [iconSizePx]="p.iconSizePx"
                                ></ohh-provider-icon-list-card>
                            </ng-container>
                        </div>

                        <div class="olpd-list-col">
                            <ng-container *ngFor="let p of contactMethods; let i = index">
                                <ohh-provider-icon-list-card
                                        *ngIf="i % 3 == 1"
                                        [name]="p.name"
                                        [icon]="p.icon"
                                        [iconSizePx]="p.iconSizePx"
                                ></ohh-provider-icon-list-card>
                            </ng-container>
                        </div>

                        <div class="olpd-list-col">
                            <ng-container *ngFor="let p of contactMethods; let i = index">
                                <ohh-provider-icon-list-card
                                        *ngIf="i % 3 == 2"
                                        [name]="p.name"
                                        [icon]="p.icon"
                                        [iconSizePx]="p.iconSizePx"
                                ></ohh-provider-icon-list-card>
                            </ng-container>
                        </div>

                    </div>
                </div>

                <div class="olpd-body-section" *ngIf="paymentMethods?.length > 0">
                    <div class="olpd-body-header prevent-select">Accepted Payments</div>
                    <div class="olpd-body-content">

                        <div class="olpd-list-col">
                            <ng-container *ngFor="let p of paymentMethods; let i = index">
                                <ohh-provider-icon-list-card
                                        *ngIf="i % 3 == 0"
                                        [name]="p.name"
                                        [icon]="'check'"
                                        [iconSizePx]="14"
                                ></ohh-provider-icon-list-card>
                            </ng-container>
                        </div>
                        <div class="olpd-list-col">
                            <ng-container *ngFor="let p of paymentMethods; let i = index">
                                <ohh-provider-icon-list-card
                                        *ngIf="i % 3 == 1"
                                        [name]="p.name"
                                        [icon]="'check'"
                                        [iconSizePx]="14"
                                ></ohh-provider-icon-list-card>
                            </ng-container>
                        </div>
                        <div class="olpd-list-col">
                            <ng-container *ngFor="let p of paymentMethods; let i = index">
                                <ohh-provider-icon-list-card
                                        *ngIf="i % 3 == 2"
                                        [name]="p.name"
                                        [icon]="'check'"
                                        [iconSizePx]="14"
                                ></ohh-provider-icon-list-card>
                            </ng-container>
                        </div>

                    </div>
                </div>

            </div>
        </div>


    </ohh-full-vw-constrained-container>

    <ohh-waiting-indicator *ngIf="isLoading" [backgroundColor]="'white'" [startFadeOut]="isDoneLoading"></ohh-waiting-indicator>

</div>

<ohh-review-writer-dlg
		*ngIf="showReviewDialog"
		[isAdminReview]="isAdminReview"
		[providerId]="provider?.id"
		(reviewSaved)="onReviewSaved($event)"
		(cancel)="showReviewDialog = !showReviewDialog"
></ohh-review-writer-dlg>
