import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'ohh-rating-selector',
    templateUrl: './rating-selector.component.html',
    styleUrl: './rating-selector.component.less',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RatingSelectorComponent),
            multi: true,
        },
    ],
})
export class RatingSelectorComponent implements ControlValueAccessor, OnInit {
    private readonly UNSELECTED = ['far', 'star'];
    private readonly SELECTED = 'star';

    value: number = 0;

    @Input() fontSize = 16;

    protected ratingIcons: any[] = [this.UNSELECTED, this.UNSELECTED, this.UNSELECTED, this.UNSELECTED, this.UNSELECTED];

    private currentRating = 0;

    ngOnInit() {
        // if (this.rating > 0) {
        //     this.onRatingChange( this.rating - 1);
        // }
    }

    //
    // ControlValueAccessor Methods
    //

    // Callbacks for change and touch events
    onChange = (value: any) => {};
    onTouched = () => {};

    // Required by ControlValueAccessor: Writes a new value to the component
    writeValue(value: any): void {
        this.value = value;

        if (this.value > 0) {
            this.onRatingChange( this.value - 1);
        }
    }

    // Registers a callback function that should be called when the value changes in the UI
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Registers a callback function that should be called when the control is touched
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Optionally, sets whether the component is disabled
    setDisabledState?(isDisabled: boolean): void {
        // You can disable the input here
    }

    // // Handles the input event in the UI, updating the value and calling the registered `onChange` function
    // onInput(event: Event): void {
    //     const value = (event.target as HTMLInputElement).value;
    //     this.value = value;
    //     this.onChange(value); // Notify Angular of the change
    // }

    // Handles the blur event to mark the field as touched
    onBlur(): void {
        this.onTouched(); // Notify Angular that the control was touched
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onRatingChange(index: number) {
        let newIcon : any = this.SELECTED;
        let rating = index + 1;

        if (index == 0 && this.ratingIcons[index] == this.SELECTED ) {
            newIcon = (this.ratingIcons[index + 1] == this.SELECTED) ? this.SELECTED : this.UNSELECTED;
            if (newIcon == this.UNSELECTED) rating = 0;
        }

        for (let i = 0; i < this.ratingIcons.length; i++) {
            this.ratingIcons[i] = (i <= index ? newIcon : this.UNSELECTED);
        }

        this.value = rating;
        this.onChange(this.value); // Notify Angular of the change
    }
}
