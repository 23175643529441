import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OhhProviderReview } from '../../../api/provider/models/ohh-provider-review.model';

@Component({
    selector: 'ohh-review-writer-dlg',
    templateUrl: './review-writer-dlg.component.html',
    styleUrl: './review-writer-dlg.component.less'
})
export class ReviewWriterDlgComponent implements OnInit {

    @Output() reviewSaved = new EventEmitter<OhhProviderReview>();
    @Output() cancel = new EventEmitter();

    @Output() showSpinnerChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _showSpinner = false;
    @Input() set showSpinner(value: boolean) {
        if (value == this._showSpinner) { return; }

        this._showSpinner = value;
        this.showSpinnerChange.emit(value);
    }

    @Input() isAdminReview = false;
    @Input() providerId: string;
    @Input() review: OhhProviderReview;

    get showSpinner(): boolean {
        return this._showSpinner;
    }

    @Input() title = 'CASE_SENSITIVE.WRITE_REVIEW';

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    ngOnInit() {
        this.showSpinner = false;
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onReviewSaved(review: OhhProviderReview) {
        this.reviewSaved.next(review);
    }

    protected onCancel() {
        this.cancel.emit();
    }

}
