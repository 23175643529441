import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { CreateOhUserModel } from './models/create-ohh-user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OhhProviderReview } from '../provider/models/ohh-provider-review.model';
import { OhhUser } from './models/ohh-user.model';

@Injectable({
    providedIn: 'root'
})
export class OhhUserApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    createUser(userModel: CreateOhUserModel): Observable<OhhUser> {
        return this.apiService.apiRequest<any>(OhhUserApiService.createUserUrl, HttpMethod.Post, userModel)
            .pipe(map((response: OhhUser) => new OhhUser(response)));
    }

    updateUser(userModel: CreateOhUserModel): Observable<OhhUser> {
        return this.apiService.apiRequest<any>(OhhUserApiService.updateUserUrl, HttpMethod.Put, userModel)
            .pipe(map((response: OhhUser) => new OhhUser(response)));
    }

    getUserById(id: string): Observable<OhhUser> {
        return this.apiService.apiRequest<any>(OhhUserApiService.getUserUrl(id), HttpMethod.Get)
            .pipe(map((response: OhhUser) => new OhhUser(response)));
    }

    setAsProvider(userId: string): Observable<boolean> {
        return this.apiService.apiRequest<any>(OhhUserApiService.setUserAsProviderUrl(userId), HttpMethod.Put)
    }

    // Gets a list of reviews written by the given user.
    getReviewsWrittenByUser(userId: string): Observable<OhhProviderReview[]> {
        return this.apiService.apiRequest<any>(OhhUserApiService.getReviewsWrittenByUserUrl(userId), HttpMethod.Get)
            .pipe(map((response: OhhProviderReview[]) => response.map(r => new OhhProviderReview(r))));
    }

    getReviewsWrittenByUserForProvider(userId: string, providerId: string): Observable<OhhProviderReview> {
        return this.apiService.apiRequest<any>(OhhUserApiService.getReviewsWrittenByUserForProviderUrl(userId, providerId), HttpMethod.Get)
            .pipe(map((response: OhhProviderReview[]) => new OhhProviderReview(response)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}ohhUser`; }

    private static get createUserUrl(): string { return `${OhhUserApiService.baseUrl}`; }

    private static get updateUserUrl(): string { return `${OhhUserApiService.baseUrl}`; }

    private static getUserUrl(id: string): string { return `${OhhUserApiService.baseUrl}/${id}`; }

    private static setUserAsProviderUrl(id: string): string { return `${OhhUserApiService.baseUrl}/${id}/setAsProvider`; }

    private static getReviewsWrittenByUserUrl(userId: string): string { return `${OhhUserApiService.baseUrl}/${userId}/reviews`; }

    private static getReviewsWrittenByUserForProviderUrl(userId: string, providerId: string): string {
        return `${OhhUserApiService.baseUrl}/${userId}/providers/${providerId}/reviews`;
    }
}
