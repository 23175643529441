import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../../../../../environments/environment';
import { OhhProviderReview } from '../../../../api/provider/models/ohh-provider-review.model';

@Component({
    selector: 'ohh-review-card',
    templateUrl: './review-card.component.html',
    styleUrl: './review-card.component.less'
})
export class ReviewCardComponent implements OnInit {
    @Output() editReview = new EventEmitter<OhhProviderReview>();
    @Output() deleteReview = new EventEmitter<OhhProviderReview>();

    protected reviewBody: string;
    protected ratingIcons: any[];
    protected client: string;
    protected editBtnText: string;
    protected isAdminReview = false;

    private _review: OhhProviderReview;
    @Input() set review(value: OhhProviderReview) {
        if (value == null) return;

        this._review = value;
        this.reviewBody = `"${this._review?.review}"`;
        this.client = this._review?.client ? `- ${this._review.client}` : undefined;
        this.setRatingIcons();
    }

    get review(): OhhProviderReview {
        return this._review;
    }

    @Input() canEdit: boolean = false;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private confirmationService: ConfirmationService,) {}

    ngOnInit() {
        this.isAdminReview = environment.allowAdminReviews && this.review.customerId == null;
        this.editBtnText = !this.isAdminReview ? 'Edit Your Review' : 'Edit Review';
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onEditReview() {
        this.editReview.next(this.review);
    }

    onDeleteReview() {
        this.confirmationService.confirm({
            header: 'Confirm Deletion',
            message: 'Are you sure you want to delete this review?',
            acceptIcon: 'none',
            rejectIcon: 'none',
            icon: 'pi pi-question-circle',
            key: this.review.id,
            accept: () => {
                this.deleteReview.next(this.review);
            },
            reject: () => {
                console.log();
            }
        })
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private setRatingIcons() {
        this.ratingIcons = [];
        const iconBase: any[] = ['far', 'star'];

        for (let i = 0; i < 5; i++) {
            let icon: any;

            if (i < this.review?.rating) {
                icon = (this.review?.rating - i >= 1 || this.review?.rating == 0) ? 'star' : 'star-half-stroke';
            } else {
                icon = iconBase;
            }

            this.ratingIcons.push(icon);
        }
    }
}
