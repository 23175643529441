import OhhColors = OhhEnums.OhhColors;
import ImageType = OhhEnums.ImageType;
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize, forkJoin, Observable, take } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { BusinessProfileComponent } from './business-profile/business-profile.component';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import { EditableComponent } from '../../shared/components/editable-component';
import { ErrorLoggingService } from '../../common/services/error-logging.service';
import { OhhEnums } from '../../common/enumerations/ohh.enums';
import { OhhProvider } from '../../api/provider/models/ohh-provider.model';
import { OhhProviderInfoUpdate } from '../../api/provider/models/ohh-provider-info-update.model';
import { OhhUser } from '../../api/ohh-user/models/ohh-user.model';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';
import { ProviderManagerService } from '../../api/provider/provider-manager.service';

@UntilDestroy()
@Component({
    selector: 'ohh-user-account',
    templateUrl: './user-account.component.html',
    styleUrl: './user-account.component.less'
})
export class UserAccountComponent extends EditableComponent implements OnInit {
    @ViewChild('fileInput') fileInput!: ElementRef;
    @ViewChild('bizFileInput') bizFileInput!: ElementRef;

    @ViewChild(CustomerProfileComponent) customerProfileComponent!: CustomerProfileComponent;
    @ViewChild(BusinessProfileComponent) businessProfileComponent!: BusinessProfileComponent;

    protected readonly OhhEnums = OhhEnums;
    protected readonly OhhColors = OhhColors;
    protected readonly ImageType = ImageType;

    // Form / Sub-Form Groups
    protected form: FormGroup;
    protected customerProfileGroup: FormGroup;
    protected businessProfileGroup: FormGroup;

    protected isComponentInitialized = false;
    protected isSaving = false;
    protected isProvider = false;
    protected pageTitle: string;
    protected ohhUser: OhhUser = null;
    protected saveSuccessful = false;
    protected provider: OhhProvider;
    protected isNewUser = false;
    protected isNewProvider = false;

    private initialFormValues: any;                     // TODO: Do we still need this? I don't think so.

    private _customerProfileFormHasChanges = false;
    protected set customerProfileFormHasChanges(value: boolean) {
        this._customerProfileFormHasChanges = value;
        this.setParentFormChangeState();
    }

    protected get customerProfileFormHasChanges(): boolean {
        return this._customerProfileFormHasChanges;
    }

    private _businessProfileFormHasChanges = false;
    protected set businessProfileFormHasChanges(value: boolean) {
        this._businessProfileFormHasChanges = value;
        this.setParentFormChangeState();
    }

    protected get businessProfileFormHasChanges(): boolean {
        return this._businessProfileFormHasChanges;
    }

    setParentFormChangeState() {
        if (!this.isComponentInitialized) return;

        if (this.form.dirty && !this.customerProfileFormHasChanges && !this.businessProfileFormHasChanges) {
            // this.form.reset();
            this.form.markAsPristine();
        }
    }

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private logger: ErrorLoggingService,
                private fb: FormBuilder,
                private ohhUserManager: OhhUserManagerService,
                private providerManager: ProviderManagerService,
                private route: ActivatedRoute
    ) {
        super();
    }

    override ngOnInit() {
        super.ngOnInit();

        this.isNewUser = this.route.snapshot.queryParamMap.get('newUser') == 'true';
        this.isNewProvider = this.route.snapshot.queryParamMap.get('newProvider') == 'true';

        // Access the static data from the route
        this.route.data.subscribe(data => {
            this.isProvider = data['isProvider'];
            this.pageTitle = this.isProvider ? 'CASE_SENSITIVE.PROVIDER_ACCOUNT' : 'CASE_SENSITIVE.CUSTOMER_PROFILE';
        });

        this.ohhUser = this.ohhUserManager.ohhUser;

        this.form = this.fb.group({
            customerProfile: this.fb.group({}),
            businessProfile: this.fb.group({})
        });

        this.customerProfileGroup = this.form.get('customerProfile') as FormGroup;
        this.businessProfileGroup = this.form.get('businessProfile') as FormGroup

        this.ohhUserManager.userLogInStateChanged
            .pipe(untilDestroyed(this))
            .subscribe((user: OhhUser) => {
                this.ohhUser = user;
            });

        this.getComponentData();

        this.form.valueChanges.subscribe(changes => {
            if (this.customerProfileGroup == null || this.businessProfileGroup == null) {return;}

            if (this.customerProfileComponent?.hasChanges() || this.businessProfileComponent?.hasChanges()) {
                this.form.dirty;
            } else {
                this.form.markAsPristine();
            }
        });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSubmit() {
        this.isSaving = true;

        const customerProfileChanges = this.form.get('customerProfile')?.value;
        Object.assign(this.ohhUser, customerProfileChanges);

        const sources: Observable<any>[] = [
            this.ohhUserManager.updateUser(this.ohhUser),
        ];

        if (this.isProvider) {
            const businessProfileChanges = this.form.get('businessProfile')?.value;
            const providerInfoUpdate = new OhhProviderInfoUpdate({ ...businessProfileChanges, id: this.ohhUser.id });

            sources.push(this.providerManager.updateProviderInfo(this.ohhUser.id, providerInfoUpdate));
        }

        forkJoin(sources)
            .pipe(finalize(() => {
                this.isSaving = false;
                setTimeout(() => this.saveSuccessful = false);
            }))
            .subscribe({
                next: ([ohhUser, providerInfo]) => {
                    console.log('Success!');
                    this.form.markAsPristine();
                    this.form.markAsUntouched()

                    this.initialFormValues = this.form.value;
                    this.saveSuccessful = true;
                },
                error: err => {
                    console.error(err);
                    this.logger.handleError(new Error('Unable to update user profile.', { cause: err }));
                    this.saveSuccessful = true;
                }
            });

        // }, 1000);
    }

    // =========================================================================================================================================================
    // helper Methods
    // =========================================================================================================================================================

    protected hasChanges(): boolean {
        return this.form.dirty && JSON.stringify(this.form.getRawValue()) !== JSON.stringify(this.initialFormValues);
    }

    private getComponentData() {
        const sources: Observable<any>[] = [];

        setTimeout(() => {
            if (this.isProvider) {
                sources.push(this.providerManager.getProvider(this.ohhUser.id).pipe(take(1)))
            } else {
                this.isComponentInitialized = true;
                return;
            }

            forkJoin(sources)
                .pipe(finalize(() => this.isComponentInitialized = true))
                .subscribe({
                    next: ([provider]) => {
                        this.provider = provider;
                    }, error: err => {
                        this.logger.handleError(new Error('Unable to retrieve component data.', { cause: err }));
                    }
                });
        }, 1000);
    }

    // =========================================================================================================================================================
    // Field Validation
    // =========================================================================================================================================================

    protected fieldIsInvalid(fieldName: string) {
        return this.form.get(fieldName).invalid && this.form.get(fieldName).touched && this.form.get(fieldName).dirty;
    }

}
