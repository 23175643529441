import ImageType = OhhEnums.ImageType;

import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable, tap } from 'rxjs';
import { AddImageModel } from './models/add-image.model';
import { HttpClient } from '@angular/common/http';
import { ImageReference } from './models/image-reference.model';
import { Injectable } from '@angular/core';
import { OhhEnums } from '../../common/enumerations/ohh.enums';

@Injectable({
    providedIn: 'root'
})
export class ImageApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    addImageReference(imageInfo: AddImageModel): Observable<ImageReference> {
        return this.apiService.apiRequest<any>(ImageApiService.addImageUrl, HttpMethod.Post, imageInfo)
            .pipe(map((response: ImageReference) => new ImageReference(response)))
    }

    deleteImageReference(imageId: string): Observable<any> {
        return this.apiService.apiRequest<any>(ImageApiService.deleteImageRefUrl(imageId), HttpMethod.Delete);
    }

    getProfilePictureUri(): Observable<string> {
        return this.apiService.apiRequest<any>(ImageApiService.profilePictureUriUrl, HttpMethod.Get)
            .pipe(map((response: any) => response.uri));
    }

    getBizProfilePictureUri(): Observable<string> {
        return this.apiService.apiRequest<any>(ImageApiService.bizProfilePictureUriUrl, HttpMethod.Get)
            .pipe(map((response: any) => response.uri));
    }

    getProfilePictureRefs(imageType: ImageType): Observable<ImageReference[]> {
        return this.apiService.apiRequest<any>(ImageApiService.profilePictureRefsUri(imageType), HttpMethod.Get)
            .pipe(map((response: ImageReference[]) => response.map(i => new ImageReference(i))));

    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}image`; }

    private static get addImageUrl(): string { return `${ImageApiService.baseUrl}`; }

    private static get profilePictureUriUrl(): string { return `${ImageApiService.baseUrl}/profilePictureUri`; }

    private static get bizProfilePictureUriUrl(): string { return `${ImageApiService.baseUrl}/bizProfilePictureUri`; }

    private static profilePictureRefsUri(imageType: ImageType): string { return `${ImageApiService.baseUrl}/ProfilePictureRefs?type=${imageType}`; }

    private static deleteImageRefUrl(imageId: string): string { return `${ImageApiService.baseUrl}/${imageId}`; }
}
