import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { OhhUser } from '../../api/ohh-user/models/ohh-user.model';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';

export const notProviderGuard: CanActivateFn = (route, state) => {

    const ohhUserManager = inject(OhhUserManagerService);
    const router = inject(Router);

    return new Observable(observer => {
        ohhUserManager.getUserByToken()
            .subscribe({
                next: (user: OhhUser) => {
                    if (user?.isProvider) { router.navigate(['/']); }

                    observer.next(user == null || !user.isProvider);
                    observer.complete();
                },
                error: err => {
                    router.navigate(['/']);

                    observer.next(false);
                    observer.complete();
                }
            });
    });
};
