<div id="ol-provider-signup-container" class="ol-page-container">
    <ohh-section-header [topPadding]="40" [bottomPadding]="40">
        <div class="ol-sh-heading">
			<span style="text-transform: uppercase;">Create Your <strong>Account</strong></span>
        </div>
		<div class="ol-sh-sub-heading small-margin">
			<span *ngIf="isLoggedIn">Membership Subscription</span>
			<span *ngIf="!isLoggedIn">Log-In or Sign-up</span>
		</div>
		<div *ngIf="isLoggedIn" class="ol-sh-body small-margin">
			<em>{{ 'OHH.APP_NAME' | translate }}</em> focuses on finding qualified, skilled people to have as members on our platform. As a founding member
			you can enjoy 6 months of FREE advertising, all you pay is a one-time fee of $25 to cover the cost of your
			background check. To get started, you will need to complete your background check and provider profile.
		</div>
		<div *ngIf="!isLoggedIn" class="ol-sh-body small-margin">
			Before you can become a Service Provider, you must register an account with <em>{{ 'OHH.APP_NAME' | translate }}. If you already have an account,
			please Log In and then return to this page.</em>
		</div>
	</ohh-section-header>

	<ohh-full-vw-constrained-container *ngIf="!isLoggedIn" [bottomPadding]="100">
		<div id="ol-btn-container">
			<button type="submit" class="ol-green-button inverted" (click)="onNavigate('/log-in')">Log In</button>
			<button type="submit" class="ol-green-button inverted" (click)="onNavigate('/register')">Sign Up</button>
		</div>
	</ohh-full-vw-constrained-container>

	<ohh-full-vw-constrained-container *ngIf="isLoggedIn" [bottomPadding]="80">
		<ohh-payment></ohh-payment>
	</ohh-full-vw-constrained-container>

    <ohh-full-vw-constrained-container [backgroundColor]="OhhColors.SectionBackground">
        <ohh-page-footer></ohh-page-footer>
    </ohh-full-vw-constrained-container>
</div>
