import MessageSeverity = OhhEnums.MessageSeverity;

import * as moment from 'moment/moment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorLoggingService } from '../../../common/services/error-logging.service';
import { OhhEnums } from '../../../common/enumerations/ohh.enums';
import { OhhMessageService } from '../../../common/services/ohh-message.service';
import { OhhProviderReview } from '../../../api/provider/models/ohh-provider-review.model';
import { OhhUser } from '../../../api/ohh-user/models/ohh-user.model';
import { OhhUserManagerService } from '../../../api/ohh-user/ohh-user-manager.service';
import { ReviewManagerService } from '../../../api/review/review-manager.service';


@Component({
    selector: 'ohh-review-writer',
    templateUrl: './review-writer.component.html',
    styleUrl: './review-writer.component.less'
})
export class ReviewWriterComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() reviewSaved = new EventEmitter<OhhProviderReview>();

    @Input() isAdminReview = false;
    @Input() providerId: string;
    @Input() review: OhhProviderReview;

    private ohhUser: OhhUser;

    // =========================================================================================================================================================
    // Reactive Form Set up
    // =========================================================================================================================================================

    form = new FormGroup({
        title: new FormControl(''),
        rating: new FormControl(0),
        review: new FormControl('', { validators: [Validators.required] }),
        reviewer: new FormControl('')
    });

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private ohhUserManager: OhhUserManagerService,
                private logger: ErrorLoggingService,
                private messageService: OhhMessageService,
                private reviewManager: ReviewManagerService
    ) { }

    ngOnInit() {
        this.ohhUser = this.ohhUserManager.ohhUser;

        if (this.review) {
            this.form.setValue({
                title: this.review.reviewTitle,
                rating: this.review.rating,
                review: this.review.review,
                reviewer: this.review.client
            });
        } else if (!this.isAdminReview) {
            this.form.get('reviewer').setValue(`${this.ohhUser?.firstName} ${this.ohhUser?.lastName[0]}.`);
        }
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onSubmit() {
        if (this.form.invalid || this.form.controls.title.invalid || this.form.controls.rating.invalid || this.form.controls.review.invalid) {
            return;

            // TODO: Figure out our error notification strategy.
        }

        const review = new OhhProviderReview({
            providerInfoId: this.providerId,
            customerId: (!this.isAdminReview && !this.ohhUserManager?.isUserAdmin) ? this.ohhUser?.id : null,
            reviewTitle: this.form.controls.title.value,
            review: this.form.controls.review.value,
            client: this.form.controls.reviewer.value,
            rating: +this.form.controls.rating.value,
            dateAddedUtc: moment.utc().toDate()
        });

        if (this.review != null) {
            review.id = this.review?.id;

            this.reviewManager.updateReview(review)
                .subscribe({
                    next: (response: OhhProviderReview) => {
                        this.reviewSaved.next(response);
                    },
                    error: err => {
                        this.messageService.showMessage("Error saving updated review", err?.error?.Error_1[0], MessageSeverity.Error);
                        this.logger.handleError(new Error('Error updating provider review.', { cause: err }));
                    }
                });
        } else {
            this.reviewManager.createReview(review)
                .subscribe({
                    next: (response: OhhProviderReview) => {
                        this.reviewSaved.next(response);
                    },
                    error: err => {
                        this.messageService.showMessage("Error Creating Review", err?.error?.Error_1[0], MessageSeverity.Error);
                        this.logger.handleError(new Error('Error creating provider review.', { cause: err }));
                    }
                });
        }
    }

    protected onCancel() {
        this.cancel.emit();
    }

    protected onSave() {

    }

    // =========================================================================================================================================================
    // Filed Validation
    // =========================================================================================================================================================

    protected fieldIsInvalid(fieldName: string) {
        return this.form.get(fieldName).invalid && this.form.get(fieldName).touched && this.form.get(fieldName).dirty;
    }

    protected fieldIsTouchedAndDirty(fieldName: string) {
        return this.form.get(fieldName).touched || this.form.get(fieldName).dirty;
    }

}
