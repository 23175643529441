
export const environment = {
    name: 'prod',
    production: true,
    appVersion: require('../../package.json').config.footerVersion,
    i18nCacheBuster: `-${require('../../package.json').config.i18nCacheBuster}`,
    suppressEmail: false,
    suppressRegistration: false,
    allowClearCheck: false,
    allowAdminReviews: false,

    ohhClientUrl: 'https://ohhlook.com',
    ohhApiUrl: 'https://api.ohhlook.com',
    ohhIdentityUrl: 'https://identity.ohhlook.com',

    ohhBlobStorageAccount: 'ohhlookstoragedev',
    ohhBlobStorageContainer: 'ohhlook-assets',
    ohhBlobStorageUrl: 'https://ohhlookstoragedev.blob.core.windows.net/ohhlook-assets',

    ohhCdnUrl: 'https://ohhlook-cdn-f2fyfehebnggb5dt.z02.azurefd.net/ohhlook-assets',

    // stripePublishKey: 'pk_live_51Kimi7FMXsw9jn8w3rr2Ue5pUQt8XqfSrjT7n6rfhjdWDJnhTKIOkWReD9AhMnOPQouozf4OUapRyGfYeB0OwYhv00QWQnQWwN',
    stripePublishKey: '',

    appInsightsInstKey: '_SetInAzure_'
}
