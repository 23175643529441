
export class MarComRecord {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    email: string;
    userId: string;
    isOptInNewsletter: boolean;
}
