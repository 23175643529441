import { ApiService, HttpMethod } from '../_common/api.service';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarComRecord } from './models/mar-com-record.model';

@Injectable({
    providedIn: 'root'
})
export class MarComApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    upsertMarComRecord(record: MarComRecord): Observable<any> {
        return this.apiService.apiRequest<any>(MarComApiService.getUpsertMarComUrl, HttpMethod.Put, record)
            .pipe(map((response: MarComRecord) => new MarComRecord(response)));
    }

    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}marCom`; }

    private static get getUpsertMarComUrl(): string { return `${MarComApiService.baseUrl}`; }
}
