<div id="ol-review-writer-container">

	<div id="ol-rw-body">
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<div class="ol-labeled-input-text">
				<label for="rating" class="prevent-select">Rating</label>
				<ohh-rating-selector id="rating" formControlName="rating" [fontSize]="20"></ohh-rating-selector>
			</div>

			<div class="ol-labeled-input-text">
				<label for="title" class="prevent-select">Title (optional)</label>
				<input pInputText id="title" formControlName="title">
			</div>

			<div class="ol-labeled-input-text">
				<label for="review" class="prevent-select">{{ 'Review' | translate }}*</label>
				<textarea
						id="review"
						class="ol-input-ta"
						pInputTextarea
						cols="30"
						rows="7"
						autoResize="true"
						formControlName="review"
				></textarea>
				<div class="ol-field-error prevent-select" *ngIf="fieldIsInvalid('review')">
					<span *ngIf="form.controls.review?.errors?.['required']">A review is required.</span>
				</div>
			</div>

			<div class="ol-labeled-input-text">
				<label for="reviewer" class="prevent-select">Reviewer</label>
				<input pInputText id="reviewer" formControlName="reviewer">
			</div>

			<div id="ol-btn-container">
				<button class="ol-green-button inverted" (click)="onCancel()">{{ 'STRINGS.CANCEL' | translate }}</button>
				<button type="submit" class="ol-green-button" [disabled]="!form.dirty || !form.valid" (click)="onSave()">{{'STRINGS.SAVE' | translate}}</button>
			</div>
		</form>
	</div>

</div>
