import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../common/services/broadcast.service';
import { ComponentBase } from '../../shared/misc/component-base';
import { OhhUserManagerService } from '../../api/ohh-user/ohh-user-manager.service';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'ohh-provider-sign-up',
    templateUrl: './provider-sign-up.component.html',
    styleUrl: './provider-sign-up.component.less'
})
export class ProviderSignUpComponent extends ComponentBase implements OnInit {

    protected isLoggedIn = false;

    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected override broadcastService: BroadcastService,
                private ohhUserManager: OhhUserManagerService,
                private router: Router
    ) {
        super(broadcastService);
    }

    override ngOnInit() {
        super.ngOnInit();

        this.ohhUserManager.userLogInStateChanged
            .pipe(untilDestroyed(this))
            .subscribe(() => this.isLoggedIn = this.ohhUserManager.ohhUser != null);

        setTimeout(() => this.isLoggedIn = this.ohhUserManager.ohhUser != null);
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    protected onNavigate(route: string) {
        this.router.navigate([`${route}`]);
    }
}
