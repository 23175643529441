import { ConfirmationService, MessageService } from 'primeng/api';
import {
    faBars,
    faCaretDown, faCheck,
    faChevronDown,
    faChevronRight,
    faChevronUp, faCircle,
    faCircleCheck, faEnvelope, faGlobe, faLocationDot, faMarker, faMessage, faPhone,
    faStar,
    faStarHalfStroke,
    faXmark
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedinIn, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleCheck as farCircleCheck, faRectangleXmark, faStar as faStarStroke} from '@fortawesome/free-regular-svg-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { EmailSignUpComponent } from './components/email-sign-up/email-sign-up.component';
import { FillPipe } from './pipes/fill.pipe';
import { FooterCarouselComponent } from './components/footer-carousel/footer-carousel.component';
import { FullVwConstrainedContainerComponent } from './components/full-vw-constrained-container/full-vw-constrained-container.component';
import { FullVwContainerComponent } from './components/full-vw-container/full-vw-container.component';
import { FullVwWImgConstrainedContainerComponent } from './components/full-vw-w-img-constrained-container/full-vw-w-img-constrained-container.component';
import { ImageCalloutCardComponent } from './components/image-callout-card/image-callout-card.component';
import { ImageCalloutContainerComponent } from './components/image-callout-container/image-callout-container.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { ImageCropperDlgComponent } from './components/image-cropper-dlg/image-cropper-dlg.component';
import { ImageModule } from 'primeng/image';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { MegaMenuComponent } from './components/mega-menu/mega-menu.component';
import { MegaMenuTopModalComponent } from './components/mega-menu/mega-menu-top-modal/mega-menu-top-modal.component';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { NgModule } from '@angular/core';
import { OhhImageCropperComponent } from './components/image-cropper/ohh-image-cropper.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProviderCardComponent } from './components/provider-card/provider-card.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingSelectorComponent } from './components/rating-selector/rating-selector.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { ReviewCardComponent } from './components/review-viewer/review-card/review-card.component';
import { ReviewViewerComponent } from './components/review-viewer/review-viewer.component';
import { ReviewWriterComponent } from './components/review-writer/review-writer.component';
import { ReviewWriterDlgComponent } from './components/review-writer-dlg/review-writer-dlg.component';
import { RippleModule } from 'primeng/ripple';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { ServicesPanelComponent } from './components/services-panel/services-panel.component';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { WaitingIndicatorComponent } from './components/waiting-indicator/waiting-indicator.component';

@NgModule({
    declarations: [
        SearchBarComponent,
        PageFooterComponent,
        FullVwConstrainedContainerComponent,
        FullVwContainerComponent,
        FullVwWImgConstrainedContainerComponent,
        SectionHeaderComponent,
        EmailSignUpComponent,
        ImageCalloutCardComponent,
        ImageCalloutContainerComponent,
        ServicesPanelComponent,
        ProviderCardComponent,
        FillPipe,
        CarouselComponent,
        MegaMenuComponent,
        MegaMenuTopModalComponent,
        UnderConstructionComponent,
        FooterCarouselComponent,
        OhhImageCropperComponent,
        ImageCropperDlgComponent,
        WaitingIndicatorComponent,
        ReadMoreComponent,
        ReviewViewerComponent,
        ReviewCardComponent,
        ReviewWriterComponent,
        ReviewWriterDlgComponent,
        RatingSelectorComponent
    ],
    imports: [
        CommonModule,
        MenubarModule,
        FontAwesomeModule,
        InputGroupModule,
        InputGroupAddonModule,
        InputTextModule,
        ButtonModule,
        TranslateModule,
        ToastModule,
        CarouselModule,
        TagModule,
        ScrollTopModule,
        SidebarModule,
        OverlayPanelModule,
        PanelMenuModule,
        AccordionModule,
        AvatarModule,
        MenuModule,
        RippleModule,
        ImageCropperComponent,
        DialogModule,
        ProgressSpinnerModule,
        TabViewModule,
        ImageModule,
        RadioButtonModule,
        InputNumberModule,
        CheckboxModule,
        InputTextareaModule,
        ChipsModule,
        ConfirmDialogModule,
        TriStateCheckboxModule,
        SkeletonModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [
        MenubarModule,
        SearchBarComponent,
        InputGroupModule,
        InputGroupAddonModule,
        ToastModule,
        CarouselModule,
        TagModule,
        ScrollTopModule,
        PageFooterComponent,
        FullVwConstrainedContainerComponent,
        FullVwContainerComponent,
        FullVwWImgConstrainedContainerComponent,
        SidebarModule,
        SectionHeaderComponent,
        EmailSignUpComponent,
        ImageCalloutCardComponent,
        ImageCalloutContainerComponent,
        OverlayPanelModule,
        ServicesPanelComponent,
        PanelMenuModule,
        ProviderCardComponent,
        CarouselComponent,
        AccordionModule,
        FillPipe,
        MegaMenuComponent,
        MegaMenuTopModalComponent,
        MegaMenuTopModalComponent,
        UnderConstructionComponent,
        FooterCarouselComponent,
        InputTextModule,
        AvatarModule,
        MenuModule,
        RippleModule,
        OhhImageCropperComponent,
        ImageCropperDlgComponent,
        ProgressSpinnerModule,
        TabViewModule,
        WaitingIndicatorComponent,
        ImageModule,
        ReadMoreComponent,
        ReviewViewerComponent,
        RadioButtonModule,
        InputNumberModule,
        CheckboxModule,
        InputTextareaModule,
        ChipsModule,
        ConfirmDialogModule,
        TriStateCheckboxModule,
        SkeletonModule,
        ReviewWriterComponent,
        ReviewWriterDlgComponent
    ],
    providers: [
        MessageService,
        ConfirmationService
    ]
})
export class SharedModule {

    constructor(library: FaIconLibrary) {
        library.addIcons(
            faCaretDown, faFacebookF, faInstagram, faYoutube, faLinkedinIn, faTiktok, faChevronUp, faChevronDown, faBars, faChevronRight, faXmark,
            faStar, faStarHalfStroke, faStarStroke, faCircleCheck, faCheck, faPhone, faGlobe, faEnvelope, faLocationDot, faCircle, faMessage, faEnvelope,
            faRectangleXmark, farCircleCheck
        )
    }
}
