import { Component } from '@angular/core';

@Component({
    selector: 'ohh-payment-response',
    templateUrl: './payment-response.component.html',
    styleUrl: './payment-response.component.less'
})
export class PaymentResponseComponent {

}
