<div id="ol-component-container">
	<div id="ol-rpe-title" class="prevent-select">
		Email Sent
	</div>
	<div (click)="onClearCheck($event)">
		<fa-icon icon="circle-check"></fa-icon>
	</div>
	<div id="ol-rpe-body" class="prevent-select" [innerHTML]="text | translate"></div>
	<div *ngIf="!suppressEmail && emailType != null && email != null" id="ol-link">
		<div *ngIf="emailType == EmailType.EmailVerify" class="ol-pseudo-link" (click)="onSendEmailVerification()"><span class="prevent-select">{{ 'PHRASE.RESEND_VERIFICATION_EMAIL' | translate }}</span></div>
		<div *ngIf="emailType == EmailType.PasswordReset" class="ol-pseudo-link" (click)="onForgotPassword()"><span class="prevent-select">{{ 'PHRASE.RESEND_PASSWORD_RESET_EMAIL' | translate }}</span></div>
	</div>
</div>
