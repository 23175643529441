import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthManagerService } from '../../api/auth/auth-manager.service';

@Component({
    selector: 'ohh-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrl: './confirm-email.component.less'
})
export class ConfirmEmailComponent implements OnInit {
    confirmationStatus = '';

    constructor(private route: ActivatedRoute,
                private authManager: AuthManagerService,
                private router: Router
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            const userId = params['userId'];
            const token = params['token'];
            const isProviderSignup = params['isProviderSignup']?.toLowerCase();

            this.authManager.confirmEmail(userId, encodeURIComponent(token))
                .subscribe({
                    next: (response: any) => {
                        this.confirmationStatus = 'Email confirmation successful.';
                        setTimeout(() => this.router.navigate(['/log-in'], { queryParams: { newUser: true, isProviderSignup: isProviderSignup } }), 3000);
                    },
                    error: err => {
                        this.confirmationStatus = 'Email confirmation failed. Please try again.';
                    }
                })
        })
    }
}
