import { ApiService, HttpMethod } from '../_common/api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StripeChargeRequest } from './models/stripe-charge-request.model';
import { StripeCreateSession } from './models/stripe-create-session.model';

@Injectable({
    providedIn: 'root'
})
export class PaymentsApiService {

    private apiService: ApiService;

    // =========================================================================================================================================================
    // Ctor
    // =========================================================================================================================================================

    constructor(http: HttpClient) {
        this.apiService = new ApiService(http);
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    createRemoteCheckoutSession(createSessionInfo: StripeCreateSession): Observable<any> {
        return this.apiService.apiRequest<any>(PaymentsApiService.createRemoteCheckoutSessionUrl, HttpMethod.Post, createSessionInfo);
    }

    createEmbeddedCheckoutSession(createSessionInfo: StripeCreateSession): Observable<any> {
        return this.apiService.apiRequest<any>(PaymentsApiService.createEmbeddedCheckoutSessionUrl, HttpMethod.Post, createSessionInfo);
    }


    // =========================================================================================================================================================
    // URLs
    // =========================================================================================================================================================

    private static get baseUrl(): string { return `${ApiService.baseApiUrl}payments`; }

    private static get chargeUrl(): string { return `${PaymentsApiService.baseUrl}/charge`; }

    private static get createRemoteCheckoutSessionUrl(): string { return `${PaymentsApiService.baseUrl}/createRemoteCheckoutSession`; }

    private static get createEmbeddedCheckoutSessionUrl(): string { return `${PaymentsApiService.baseUrl}/createEmbeddedCheckoutSession`; }
}
